import {
  addActionBarItem,
  addActionBarDropdownMenuItem,
  ChannelService,
} from "@vendure/admin-ui/core";
import { forkJoin, throwError, concatMap, from } from "rxjs";
import gql from "graphql-tag";

import { map, switchMap, tap, catchError } from "rxjs/operators";

const flushMutation = gql`
  mutation flushBufferedJobs($bufferIds: [String!]) {
    flushBufferedJobs(bufferIds: $bufferIds) {
      success
    }
  }
`;

const channelQuery = gql`
  query channels {
    channels {
      items {
        code
        id
      }
    }
  }
`;

export default [
  addActionBarItem({
    id: "bulk-stock-update",
    label: "Bulk Stock Update",
    locationId: "product-detail",
    buttonColor: "primary",
    buttonStyle: "outline",
    buttonState: (context) => {
      return context.route.data.pipe(
        switchMap((data) => data.detail.entity),
        map((product: any) => {
          return {
            disabled: product.enabled === false,
            visible: !!product,
          };
        })
      );
    },
    onClick(event, context) {
      const productId = context.route.snapshot.params.id;

      const stockNum = prompt("Enter new stock level");

      if (stockNum) {
        const stockOnHand = parseInt(stockNum, 10);
        if (isNaN(stockOnHand)) {
          return alert("Please enter a valid number");
        }

        if (stockOnHand < 0) {
          return alert("Stock level cannot be negative");
        }

        const product$ = context.dataService.product;
        const notificationService$ = context.notificationService;

        return product$
          .getProductVariantsForProduct({}, productId)
          .single$.pipe(
            map((data) => {
              return data.productVariants.items.map((variant) => ({
                id: variant.id,
                stockOnHand,
              }));
            }),
            switchMap((updatedVariants) => {
              return product$.updateProductVariants(updatedVariants);
            }),
            tap(() => {
              notificationService$.success(
                "Product variants stock level updated successfully"
              );
              // window.location.reload();
            }),
            catchError((error) => {
              notificationService$.error("Error updating product variants");
              console.error("Error updating product variants", error);
              return throwError(error);
            })
          )
          .subscribe((d) => {
            window.location.reload();
          });
      }
    },
  }),
];
