import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PagesUiExtensionModule } from './extensions/97c899af43950bd3ad402087607d95a3d5b59be384a98b3f7a810fc645cb36fc/pages-ui-extension.module';
import { BannerUiExtensionSharedModule } from './extensions/d0f01be1921645f0075fa46e5f95db1dcd531add619aeaca4b4a3b68720af5e1/banner-ui-extension.module';
import { MetricsWidgetSharedModule } from './extensions/40c64e4fa63f78a7e32cd0a5a7c3204b3b514e42173e74f1f7742e8e15ba5c2a/metrics-widget.shared-module';
import { CancelOrderButtonModule } from './extensions/3ed54715dbdb35654aa54f259018c10c007a72b773958ed30fdbd3912c6eb56e/cancel-order-button.module';
import { CompleteOrderButtonModule } from './extensions/4ab679c78c5f3333a333626ac05c21c9220c1b5d94048e10a90514b5cd645209/complete-order-button.module';

import SharedProviders_2_0 from './extensions/038cec725e6344240355f5e0d752ff46c553bbec84830ed072a67e77b2e6945d/providers';
import SharedProviders_4_0 from './extensions/64ff40ac728c283f20501145ff2588f11f4c536c3c792ad60977952125594143/providers';
import SharedProviders_5_0 from './extensions/1c484ec128b134d894689f6bf42f8d57fcf5b8dbb8255e037a8c1feb9b5b72c1/providers';


@NgModule({
    imports: [CommonModule, PagesUiExtensionModule, BannerUiExtensionSharedModule, MetricsWidgetSharedModule, CancelOrderButtonModule, CompleteOrderButtonModule],
    providers: [...SharedProviders_2_0, ...SharedProviders_4_0, ...SharedProviders_5_0],
})
export class SharedExtensionsModule {}
