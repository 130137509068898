import { Component } from "@angular/core";
import { FormControl } from "@angular/forms";
import {
  SharedModule,
  FormInputComponent,
  StringCustomFieldConfig,
} from "@vendure/admin-ui/core";

@Component({
  templateUrl: "./color-picker-input.component.html",
  styleUrl: "./color-picker-input.component.scss",
  standalone: true,
  imports: [SharedModule],
})
export class ColorPickerInputComponent
  implements FormInputComponent<StringCustomFieldConfig>
{
  readonly: boolean;
  config: StringCustomFieldConfig;
  formControl: FormControl;
}
